import React from 'react';
import { Helmet } from 'react-helmet';
import { WebSiteStructure } from '../StructureMarkUp';
import { CompanyInfo } from '../../ApiEndpoints';

export default props => {
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.metDescription} />
        <link rel="canonical" href={props.CanonicalLink} />
        <meta name="theme-color" content="#000000" />
        <script type="application/ld+json">
          {WebSiteStructure(CompanyInfo)}
        </script>
        {props.Schema !== undefined
          ? props.Schema.map(item => {
              return (
                <script
                  className="dynamic-structure"
                  type="application/ld+json"
                >
                  {item}
                </script>
              );
            })
          : null}
      </Helmet>
    </div>
  );
};
