const OrginationData = props => `{
    "@context": "http://schema.org",
    "@type": "${props.type}",
    "legalName": "${props.legalName}",
    "url": "${props.url}",
    "logo": "${props.logo}",
    "description" : "${props.description}",	  
    "contactPoint": [{
        "@type": "ContactPoint",
        "email": "${props.contact.email}",
        "contactType": "${props.contact.contactType}"
    }],
    "sameAs" : [
        "${props.socialLinks.twitter}",
        "${props.socialLinks.facebook}",
        "${props.socialLinks.instagram}"
    ]
  }`;

const WebSiteStructure = props => `{
    "@context": "http://schema.org",
    "@type": "WebSite",
    "name": "${props.legalName}",
    "url": "${props.url}"
}`;

//Validity Date can be change from here
const getPriceValidity = () => {
  var Today = new Date();
  var ValidityDayNo = 5;
  // you can return '12-12-2020'
  return new Date(Today.setDate(Today.getDate() + ValidityDayNo));
};

const ProductStructure = props => `{
    "@context": "http://schema.org",
    "@type": "Product",
    "name": "${props.type} Tour Packages",
    "image": "${props.thumnail}" ,
    "description": "${
      props.type
    } Tour Packages with top hotels, reliable driver and flexible itinerary.",
    "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": ${props.ratings},
        "reviewCount": ${props.reviewes} 
    },
    "offers": {
        "@type": "Offer",
        "priceCurrency": "INR",
        "price": ${props.minPrice} ,
        "priceValidUntil": "${getPriceValidity()}",
        "availability": "http://schema.org/InStock",
        "url": "${props.pageUrl}"
    },
    "brand": {
        "@type": "Thing",
        "name": "${props.brandName}"
    },
    "review": {
        "@type": "Review",
        "name": "Best Tour Packages for ${props.type}",
        "author": "Verified Purchased Travelers"
    },
    "sku": "${props.type}"
}`;

const ServicesStructure = props =>
  props.listings.length > 0
    ? `{
    "@context": "http://schema.org",
    "@type": "ItemList",
    "url":  "${props.pageUrl}",
    "name": "${props.tag}${props.city !== '' ? ` ${props.city}` : ''} ${
        props.type
      }",
    "description": "Discover ${props.tag}${
        props.city !== '' ? ` ${props.city}` : ''
      } ${props.type}",
    "itemListElement": [${getListItem(props, 'ServicesList')}]
}`
    : '';

const DetailsPageStructure = (props, listName) =>
  props.listings.length > 0
    ? `{		
    "@context": "http://schema.org",
    "@type": "ItemList",
    "itemListElement" : [${getListItem(props, 'TopServiceList')}]}`
    : '';

const getListItem = props => {
  var item = [];
  props.listings.map((obj, index) => {
    item.push(listItemStructure(obj, (index = index + 1), props));
    return obj;
  });

  return item;
};

const listItemStructure = (item, index) => {
  return `
  {
        "@type": "ListItem",    
        "name": "${item.Text}",
        "position": ${index},
        "url": "${window.location.origin}${item.path}"
    }`;
};

export {
  OrginationData,
  WebSiteStructure,
  ProductStructure,
  ServicesStructure,
  DetailsPageStructure,
};
