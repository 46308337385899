import Cookies from 'js-cookie';
export const getToken = async () => {
  const data = Cookies.get('token');
  if (data) {
    return JSON.parse(Cookies.get('token'));
  }
  return null;
};

//const DomainURL = 'http://localhost:9000';
const DomainURL = 'https://api.travelgny.com';
export const ApiEndpoints = {
  signIn: '/api/login',
  signUp: '/api/register',
  external: '/api/external',
  Services: '/api/data',
  AddFavourite: '/api/add_favourite',
  AddQuotes: '/api/add_quote',
  ServiceDetails: '/api/details',
  SearchCities: '/api/Cities',
  Destinations: '/api/destination',
  ServiceCity: '/api/dataCity/',
  All: '/api/homesearch',
  BusinessDetails: '/api/business/details',
  AddTrawel: '/api/add_trawell',
  Logger: '/api/log-client-errors',
  DynamicUrl: '/api',
  Search: '/api/search',
  AddReviews: '/api/reviews/add',
  TopSeach: '/api/ToSearch/',
  changePassword: '/api/user/changePassword',
  forgetPassword: '/api/forgetPass',
  GetAllReviews: '/api/reviews/all',
  UserSearch: '/api/ToSearch/UserSearch',
  validatePhrase: '/api/securityphrase',
  ServiceArea: '/api/areasearch',
  AreaLink: '/api/arealinks',
  topPackages: '/api/top',
  CityLinks: '/api/citysearch',
  contactUs: '/api/contactus',
};

//Structure Date for Company Information

export const CompanyInfo = {
  type: 'Organization',
  legalName: 'TravelGny.com',
  url: 'https://travelgny.com',
  foundingDate: '12-14-2019',
  description:
    'Explore top Travel Services & Tour Packages. Learn more about Travel Services & Tour Packages and discover genuine ratings, areas of expertise, hours of operation and mode of payment. Contact Travel Services & Tour Packages directly from TravelGny website.',
  logo: 'https://travelgny.com/static/media/TravelGny-Final.d747d30f.png',
  contact: {
    email: 'support@travelgny.com',
    phone: '+91-7799591230',
    contactType: 'customer service',
  },
  address: {
    city: 'Bangalore',
    region: 'Ashok Nagar',
    country: 'India',
    zipCode: '1234',
  },
  socialLinks: {
    twitter: 'https://twitter.com/TravelGny',
    facebook: 'https://www.facebook.com/pg/TravelGnycom-101453277901399',
    instagram: 'https://www.instagram.com/travelgnycorp',
  },
};

//region Fetch Mechanism
export const GetSecured = async URL => {
  let Token = await getToken();
  if (Token != null) {
    return await fetch(URL, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: Token,
        'X-Custom-Header': 'TravelGny',
      },
    });
    //return await _ReturnResponse(response);
  }
};
export const PostSecured = async (URL, params) => {
  let Token = await getToken();
  if (Token != null) {
    let response = await fetch(URL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: Token.token,
        xcustomheader: 'TravelGny',
      },
      method: 'POST',
      body: JSON.stringify(params),
    });
    return await ReturnResponse(response);
  }
};
export const Post = async (URL, params) => {
  let response = await fetch(URL, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      xcustomheader: 'TravelGny',
    },
    method: 'POST',
    body: JSON.stringify(params),
  });

  return await ReturnResponse(response);
};
export const Get = async URL => {
  let response = await fetch(URL, {
    headers: { xcustomheader: 'TravelGny' },
  });
  return await ReturnResponse(response);
};
export const parseQuery = queryString => {
  var query = {};
  var pairs = (queryString[0] === '?'
    ? queryString.substr(1)
    : queryString
  ).split('&');
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
};
export const ReturnResponse = async response => {
  if (response.ok) {
    let responseJson = await response.clone().json();
    responseJson.status = response.status;

    return responseJson;
  } else {
    response = {
      error: 'error',
      status: response.status,
      error_description:
        'Unable to process Your request Please contact your admin',
    };
    return response;
  }
};
//endregion

export default DomainURL;
