import React, { useState, useContext } from 'react';
import Sticky from 'react-stickynode';
import { Link, withRouter } from 'react-router-dom';
import { IoIosClose } from 'react-icons/io';
import Button from '@isomorphic/shared/UIView/Antd/Button/Button';
import Drawer from '@isomorphic/shared/UIView/Antd/Drawer/Drawer';
import Logo from '@isomorphic/shared/UIView/Logo/Logo';
import Text from '@isomorphic/shared/UIView/Text/Text';
import TextLink from '@isomorphic/shared/UIView/TextLink/TextLink';
import Navbar from '@isomorphic/shared/hotel/components/Navbar/Navbar';
import { AuthContext } from '../../../context/AuthProvider';
import useWindowSize from '@isomorphic/shared/Library/hooks/useWindowSize';
import { AGENT_PROFILE_PAGE } from '../../../settings/constant';
import AuthMenu from './AuthMenu';
import MainMenu from './MainMenu';
import MobileMenu from './MobileMenu';
import ProfileMenu from './ProfileMenu';
import NavbarSearch from './NavbarSearch';
import HeaderWrapper, {
  MobileNavbar,
  CloseDrawer,
  AvatarWrapper,
  AvatarImage,
  AvatarInfo,
  LogoArea,
} from './Header.style';

// Dummy images
import DemoLogo from '@isomorphic/shared/hotel/assets/images/travelLogo.jpg';
import DemoLogo_large from '@isomorphic/shared/hotel/assets/images/TravelGny-Final.png';
import AvatarImg from '@isomorphic/shared/hotel/assets/images/img_avatar.png';
import '../../../customStyle.css';

export default withRouter(function Header({ location }) {
  // auth context
  const { user, loggedIn } = useContext(AuthContext);

  // sidebar and responsive control state
  const [state, setState] = useState(false);

  // sidebar handler function
  const sidebarHandler = () => {
    setState(!state);
  };

  // useWindowSize hook
  const { width } = useWindowSize();

  // check header type
  //const headerType = location.pathname === '/' ? 'transparent' : 'default';
  const headerType = location.pathname === '/' ? 'default' : 'default';

  return (
    <HeaderWrapper>
      <Sticky top={0} innerZ={10001} activeClass="isHeaderSticky">
        {width > 991 ? (
          <Navbar
            logo={
              <Link className="logoLink d-flex-center" to="/">
                <Logo className={'logo-size'} src={DemoLogo_large} title={''} />
              </Link>
            }
            navMenu={<MainMenu />}
            authMenu={<AuthMenu />}
            isLogin={loggedIn}
            avatar={
              <Logo
                className="logoSize"
                src={user != null ? user.avatar : AvatarImg}
                title={user != null ? user.UserName : ''}
              />
            }
            profileMenu={
              <ProfileMenu
                avatar={
                  <Logo
                    className="logoSize"
                    src={user != null ? user.avatar : AvatarImg}
                    title={user != null ? user.UserName : ''}
                  />
                }
              />
            }
            headerType={headerType}
            searchComponent={
              <NavbarSearch
                placeholder={'Try “Bangalore, Karnataka, India”'}
                top={true}
              />
            }
            //searchComponent={<GroupInput className="cstm-groupInput" />}
            location={location}
            searchVisibility={true}
          />
        ) : (
          <MobileNavbar className={headerType}>
            <LogoArea>
              <Link className="logoLink" to="/">
                <Logo
                  className={'logo-size'}
                  style={{ width: '55px !important' }}
                  src={DemoLogo}
                  title="TravelGny"
                />
              </Link>
              <NavbarSearch />
            </LogoArea>
            <Button
              className={`hamburg-btn ${state ? 'active' : ''}`}
              onClick={sidebarHandler}
            >
              <span />
              <span />
              <span />
            </Button>
            <Drawer
              placement="right"
              closable={false}
              onClose={sidebarHandler}
              width="285px"
              className="mobile-header"
              visible={state}
            >
              <CloseDrawer>
                <button onClick={sidebarHandler}>
                  <IoIosClose />
                </button>
              </CloseDrawer>
              {loggedIn ? (
                <AvatarWrapper>
                  <AvatarImage>
                    <Logo
                      className="logoSize"
                      src={user != null ? user.avatar : AvatarImg}
                      title="hotel"
                    />
                  </AvatarImage>
                  <AvatarInfo>
                    <Text as="h3" content={user.UserName} />
                    <TextLink
                      link={AGENT_PROFILE_PAGE}
                      content="View Profile"
                    />
                  </AvatarInfo>
                </AvatarWrapper>
              ) : (
                <AuthMenu className="auth-menu" />
              )}
              <MobileMenu className="main-menu" />
            </Drawer>
          </MobileNavbar>
        )}
      </Sticky>
    </HeaderWrapper>
  );
});
