import React from 'react';
import scrollImg from '@isomorphic/shared/hotel/assets/images/Scroll.png';

class Scroll extends React.Component {
  render() {
    return (
      <>
        <div className="scroll" id="topToScroll">
          <img
            src={scrollImg}
            id=""
            alt="TravelGny"
            onClick={() => {
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
              });
            }}
          />
        </div>
      </>
    );
  }
}
export default Scroll;
