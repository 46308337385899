import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { openNotification } from '../container/BreadCrumbs';
import DomainURL, { Post, ApiEndpoints } from '../ApiEndpoints';
import AvatarImg from '@isomorphic/shared/hotel/assets/images/img_avatar.png';
export const AuthContext = React.createContext();

/**
 * We have used Fake JWT token from "jwt.io"
 * This is a sample token to show auth is working
 * Your token will come from your server when user successfully loggedIn.
 */

export const addItem = (key, value = '') => {
  /**
   *  Using the local storage code....
   */
  // if (key) localStorage.setItem(key, value);

  /**
   *  Using the Cookies code...
   */
  if (key) Cookies.set(key, value, { expires: 7 });
};

export const clearItem = key => {
  /**
   *  Using the local storage code....
   */
  // localStorage.removeItem(key);

  /**
   *  Using the Cookies code...
   */
  Cookies.remove(key);
};

export const getITem = key => {
  return Cookies.get(key);
};

export const setLocation = location => {
  var data = Cookies.get('token');
  if (data) {
    data = JSON.parse(data);
    data.location = location;
    Cookies.remove('token');
    Cookies.set('token', JSON.stringify(data), { expires: 7 });
    return JSON.parse(Cookies.get('token'));
  }
  return null;
};

const gettoken = () => {
  const data = Cookies.get('token');
  if (data) {
    return JSON.parse(Cookies.get('token')).token;
  }
  return null;
};

export const Login = async params => {
  let data = await Post(`${DomainURL}${ApiEndpoints.signIn}`, params);

  if (!data.error) {
    data.userData.avatar = AvatarImg;
    addItem('token', data);
    return data;
  } else {
    openNotification({
      Heading: data.error,
      type: 'error',
    });
    return data;
  }
};

export const Logout = () => {
  clearItem('token');
};

const getUser = () => {
  const data = Cookies.get('token');
  if (data) {
    return JSON.parse(Cookies.get('token')).userData;
  }
  return null;
};

const isValidToken = () => {
  /**
   *  Using the local storage code....
   */
  // const token = localStorage.getItem('token');

  /**
   *  Using the Cookies code...
   */
  const token = Cookies.get('token');
  // JWT decode & check token validity & expiration.
  if (token) return true;
  return false;
};

const AuthProvider = props => {
  const [loggedIn, setLoggedIn] = useState(isValidToken());
  // const [loggedOut, setLoggedOut] = useState(true);
  const [user, setUser] = useState(getUser());
  const [token, setToken] = useState(gettoken());

  const signIn = async (params, callback) => {
    /**
     * Make post request here to authenticate with fetch
     * if returns true then change the state
     */
    let data = await Post(`${DomainURL}${ApiEndpoints.signIn}`, params);

    if (!data.error) {
      data.userData.avatar = AvatarImg;
      setUser(data.userData);
      setToken(data);
      addItem('token', data);
      setLoggedIn(true);
      if (callback !== undefined) callback();
      return data;
    } else {
      openNotification({
        Heading: data.error,
        type: 'error',
      });
      if (callback !== undefined) callback();
      return data;
    }
  };

  const signUp = async (params, callback) => {
    console.log(params, 'sign up form Props');
    let data = await Post(`${DomainURL}${ApiEndpoints.signUp}`, params);
    if (!data.error) {
      data.userData.avatar = AvatarImg;
      setUser(data.userData);
      setToken(data.token);
      addItem('token', data);
      setLoggedIn(true);
      if (callback !== undefined) callback();
      return data;
    } else {
      openNotification({
        Heading: data.error,
        type: 'error',
      });
      if (callback !== undefined) callback();
      return data;
    }
  };

  /**
   * For 3rd-party Authentication [e.g. Autho0, firebase, AWS etc]
   *
   */
  const tokenAuth = (token, user = {}) => {
    setUser(user);
    setToken(token);
    addItem('token', token);
    setLoggedIn(true);
  };

  const forgetPass = params => {
    console.log(params, 'forget password form Props');
  };
  const changePass = params => {
    console.log(params, 'change password form Props');
  };

  const logOut = () => {
    setUser(null);
    setToken(null);
    clearItem('token');
    setLoggedIn(false);
  };

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        logOut,
        signIn,
        signUp,
        forgetPass,
        changePass,
        tokenAuth,
        user,
        token,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
