const visibilityFilter = (
  state = {
    UserData: {},
  },
  action
) => {
  switch (action.type) {
    case 'ADD_USER_DATA':
      return { ...state, UserData: action.data };
    default:
      return state;
  }
};

export default visibilityFilter;
