import React from 'react';
import { NavLink } from 'react-router-dom';
import Menu from '@isomorphic/shared/UIView/Antd/Menu/Menu';
import { Row, Col } from 'antd';
import { IoLogoTwitter, IoLogoFacebook, IoLogoInstagram } from 'react-icons/io';
import '../../../customStyle.css';

const FooterMenu = () => {
  return (
    <>
      <Menu className="footerLinks item-center">
        <Menu.Item key="0">
          <NavLink to={`/contact`}>Contact Us</NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to={`/career`}>Careers</NavLink>
        </Menu.Item>
        <Menu.Item key="4">
          <NavLink to={`/privacy`}>Privacy Policy</NavLink>
        </Menu.Item>
      </Menu>
      <Row justify="start" className="mt-30">
        <Col md={24} xs={24}>
          <div className="d-flex-center">
            <a
              href="https://www.facebook.com/TravelGny"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IoLogoFacebook className="facebook customIcons" />
            </a>
            <a
              href="https://twitter.com/TravelGny_com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IoLogoTwitter className="facebook customIcons" />
            </a>
            <a
              href="https://www.instagram.com/TravelGny_com "
              target="_blank"
              rel="noopener noreferrer"
            >
              <IoLogoInstagram className="facebook customIcons" />
            </a>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default FooterMenu;
