export const dynamicRouteP = Params => {
  return `${
    Params.package !== '' && Params.package !== undefined
      ? `/${hyphenSeparated(Params.package)}`
      : ''
  }${
    Params.city !== '' &&
    Params.city !== undefined &&
    Params.city.toLocaleLowerCase() !== 'nearby'
      ? `/${hyphenSeparated(Params.city)}`
      : ''
  }${Params.area !== undefined ? getArea(Params) : ''}`;
};

const getArea = Params => {
  if (Params.city !== undefined && Params.city !== '') {
    return `/${hyphenSeparated(Params.area)}`;
  } else if (Params.area !== undefined && Params.area !== '') {
    return `/nearby/${hyphenSeparated(Params.area)}`;
  } else {
    return '';
  }
};

export const dynamicRouteParams = Params => {
  return {
    type:
      Params.package !== '' && Params.package !== undefined
        ? `${hyphenSeparated(Params.package)}`
        : '',
    city:
      Params.city !== '' &&
      Params.city !== undefined &&
      Params.city.toLocaleLowerCase() !== 'nearby'
        ? `${hyphenSeparated(Params.city)}`
        : '',
    area:
      Params.area !== '' && Params.area !== undefined
        ? `${hyphenSeparated(Params.area)}`
        : '',
  };
};

export const hyphenSeparated = data => {
  return data
    .toLocaleLowerCase()
    .split(' ')
    .join('-');
};

export const dynamicRouteT = Params => {};

export const isPackageTheme = isPackage => {
  var Themes = [
    'Honeymoon',
    'Wildlife',
    'Hill Station',
    'Beach',
    'Waterfall',
    'Heritage',
    'Pilgrimage',
    'Adventure',
  ];
  return Themes.filter(O => O.toLowerCase() === isPackage.toLowerCase()).length;
};
