import React from 'react';
import { NavLink } from 'react-router-dom';
import Menu from '@isomorphic/shared/UIView/Antd/Menu/Menu';

import { BUSINESS_DETAILS_PAGE } from '../../../settings/constant';

const MainMenu = ({ className }) => {
  return (
    <Menu className={className}>
      <Menu.Item key="0">
        <NavLink exact to={BUSINESS_DETAILS_PAGE}>
          List Business for Free
        </NavLink>
      </Menu.Item>
      {/* <Menu.Item key="0">
        <NavLink exact to={`${HOME_PAGE}`}>
          Hotels
        </NavLink>
      </Menu.Item> */}
      {/* <Menu.Item key="1">
        <NavLink to={`${LISTING_POSTS_PAGE}`}>Listing</NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink to={`${AGENT_PROFILE_PAGE}`}>Agent</NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink to={`${PRICING_PLAN_PAGE}`}>Pricing</NavLink>
      </Menu.Item> */}
    </Menu>
  );
};

export default MainMenu;
