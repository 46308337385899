// **************** ROUTE CONSTANT START **************************
// General Page Section
export const HOME_PAGE = '/';
export const AGENTS_PAGE = '/agents';

export const CUST_HOME = '/custom';

//
export const TRAVEL_PAGE = '/travel';
export const HOME_SERVICES_PAGE = '/ts/:type';
export const HOME_PACKAGES_PAGE = '/packages';

export const HOME_ALL = '/all';

export const SERVICE = '/ts';
export const SERVICE_TYPE = '/ts/:type';
export const PACKAGE_TYPE = '/tour-packages';

export const SERVICE_DETAILS_PAGE = '/ts/:type/:city';

export const SERVICE_DETAILS_SINGLE = '/ts/:type/:city/:title/:id';

export const SERVICE_IN_CITY = '/ts/:type/:city';

export const SERVICE_IN_AREA = '/ts/:type/:city/:area';

export const SERVICE_NEARBY_AREA = '/ts/:type/nearby/:area';

export const PACKAGE_DETAILS_PAGE = '/tour-packages/:type';

export const PACKAGE_DETAILS_SINGLE = '/tour-packages/:type/:title';

export const PACKAGE_FROM_DEST = '/tour-packages/:type/from/:dest';

export const VIEW_SERVICE_DETAILS_PAGE = '/ts/:type/:city/:area/:title/:id';

//Business
export const BUSINESS_DETAILS_PAGE = '/business-details';

// Listing Single Page Section
export const LISTING_POSTS_PAGE = '/listing';
export const SINGLE_POST_PAGE = '/post';

// Agent Profile Page Section
export const AGENT_PROFILE_PAGE = '/profile';
export const AGENT_ACCOUNT_SETTINGS_PAGE = '/account-settings';
export const AGENT_PROFILE_EDIT_PAGE = '/edit';
export const AGENT_IMAGE_EDIT_PAGE = '/change-image';
export const AGENT_PASSWORD_CHANGE_PAGE = '/change-password';
export const AGENT_PROFILE_DELETE = '/delete';
export const AGENT_PROFILE_FAVOURITE = '/favourite-post';
export const AGENT_PROFILE_CONTACT = '/contact';
export const ADD_HOTEL_PAGE = '/add';

// Other Pages
export const PRICING_PLAN_PAGE = '/pricing-plan';
export const PRIVACY_PAGE = '/privacy';
export const CAREER_PAGE = '/career';

// Login / Registation Page
// export const AUTHENTICATION_PAGE = '/user';
export const LOGIN_PAGE = '/login';
export const REGISTRATION_PAGE = '/registration';
export const CHANGE_PASSWORD_PAGE = '/change-password';
export const FORGET_PASSWORD_PAGE = '/forgot-password';
// **************** ROUTE CONSTANT END **************************
