import styled from 'styled-components';

export const NotificationContent = styled.div`
  display: inline-block;
  font-size: 13px;
`;

export const CSRating = styled.div`
  unicode-bidi: bidi-override;
  color: #ccc;
  font-size: 16px;
  position: relative;
  margin: 0;
  padding: 0;
  margin-left: 10px;
  margin-right: 10px;
`;

export const RatingFill = styled.div`
  color: #e7711b;
  padding: 0;
  position: absolute;
  z-index: 1;
  display: block;
  top: 0;
  left: 0;
  overflow: hidden;

  // Allows us to grab the width of the span elements
  > span {
    display: inline-block;
  }
`;

export const EmptyRatings = styled.div`
  padding: 0;
  display: block;
  z-index: 0;
`;
