import React from 'react';
import Logo from '@isomorphic/shared/UIView/Logo/Logo';
import Footers from '@isomorphic/shared/hotel/components/Footer/Footer';
import { Link } from 'react-router-dom';
import LogoImage from '@isomorphic/shared/hotel/assets/images/travelLogo.jpg';
import DemoLogo_large from '@isomorphic/shared/hotel/assets/images/TravelGny-Final.png';
import FooterMenu from './FooterMenu';
import { Row } from 'antd';
import Scroll from '../../Scroll/index';
import Container from '@isomorphic/shared/UIView/UI/Container/Container';
const Footer = () => {
  return (
    <>
      <Footers
        logo={
          <Link to="/">
            <Logo
              className="logo-size logo_lg"
              src={DemoLogo_large}
              title="TravelGny"
            />
            <Logo
              className="Logo_sm"
              style={{ width: '55px !important' }}
              src={LogoImage}
              title="TravelGny"
            />
          </Link>
        }
        menu={<FooterMenu />}
        copyright={`Copyright @ ${new Date().getFullYear()} TravelGny.`}
      />
      <Container>
        <Row className="d-flex-center">
          <Scroll />
        </Row>
      </Container>
    </>
  );
};

export default Footer;
