import React, { useContext } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { AuthContext } from './context/AuthProvider';
import Layout from './container/Layout/Layout';
import {
  LOGIN_PAGE,
  REGISTRATION_PAGE,
  FORGET_PASSWORD_PAGE,
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  SINGLE_POST_PAGE,
  ADD_HOTEL_PAGE,
  AGENT_PROFILE_PAGE,
  AGENT_ACCOUNT_SETTINGS_PAGE,
  PRIVACY_PAGE,
  PRICING_PLAN_PAGE,
  CUST_HOME,
  TRAVEL_PAGE,
  HOME_PACKAGES_PAGE,
  SERVICE_DETAILS_PAGE,
  SERVICE_DETAILS_SINGLE,
  SERVICE_IN_CITY,
  SERVICE_IN_AREA,
  PACKAGE_DETAILS_PAGE,
  PACKAGE_DETAILS_SINGLE,
  VIEW_SERVICE_DETAILS_PAGE,
  HOME_ALL,
  BUSINESS_DETAILS_PAGE,
  SERVICE_NEARBY_AREA,
  SERVICE_TYPE,
  PACKAGE_TYPE,
  SERVICE,
  CAREER_PAGE,
  AGENT_PROFILE_CONTACT,
} from './settings/constant';

/**
 *
 * Public Routes
 *
 */
const Loading = () => null;

const routes = [
  {
    path: PACKAGE_TYPE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "PackageDetails" */ './container/Seo/package'
        ),
      loading: Loading,
      modules: ['PackageSeo'],
    }),
    exact: true,
  },
  {
    path: SERVICE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "PackageDetails" */ './container/Seo/services'
        ),
      loading: Loading,
      modules: ['ServicesSeo'],
    }),
    exact: true,
  },
  {
    path: SERVICE_TYPE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "PackageDetails" */ './container/Seo/servicesType'
        ),
      loading: Loading,
      modules: ['ServicesTypeSeo'],
    }),
    exact: true,
  },
  {
    path: PACKAGE_DETAILS_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "PackageDetails" */ './container/PackageDetails/index'
        ),
      loading: Loading,
      modules: ['PackageDetails'],
    }),
    exact: true,
  },
  {
    path: PACKAGE_DETAILS_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "PackageDetails" */ './container/PackageDetails/index'
        ),
      loading: Loading,
      modules: ['PackageDetails'],
    }),
    exact: true,
  },
  {
    path: PACKAGE_DETAILS_SINGLE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "PackageDetails" */ './container/PackageDetails/index'
        ),
      loading: Loading,
      modules: ['PackageDetails'],
    }),
    exact: true,
  },
  {
    path: SERVICE_DETAILS_SINGLE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "ServiceDetails" */ './container/ViewServiceDetails/index'
        ),
      loading: Loading,
      modules: ['ServiceDetails'],
    }),
    exact: true,
  },
  {
    path: [SERVICE_DETAILS_PAGE],
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "ServiceDetails" */ './container/ServiceDetails/index'
        ),
      loading: Loading,
      modules: ['ServiceDetails'],
    }),
    exact: true,
  },
  {
    path: [SERVICE_IN_CITY],
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "ServiceDetails" */ './container/ServiceDetails/index'
        ),
      loading: Loading,
      modules: ['ServiceDetails'],
    }),
    exact: true,
  },
  {
    path: [SERVICE_IN_AREA],
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "ServiceDetails" */ './container/ServiceDetails/index'
        ),
      loading: Loading,
      modules: ['ServiceDetails'],
    }),
    exact: true,
  },
  {
    path: [SERVICE_NEARBY_AREA],
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "ServiceDetails" */ './container/ServiceDetails/index'
        ),
      loading: Loading,
      modules: ['ServiceDetails'],
    }),
    exact: true,
  },
  {
    path: HOME_ALL,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "All" */ './container/AllPackages'),
      loading: Loading,
      modules: ['All'],
    }),
    exact: true,
  },
  {
    path: VIEW_SERVICE_DETAILS_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "ServiceDetails" */ './container/ViewServiceDetails/index'
        ),
      loading: Loading,
      modules: ['ViewServiceDetails'],
    }),
    exact: true,
  },
  {
    path: HOME_PACKAGES_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "PackageServices" */ './container/Packages/Tablines/AgentDetailsViewPage'
        ),
      loading: Loading,
      modules: ['PackageServices'],
    }),
    exact: true,
  },
  {
    path: TRAVEL_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "PackageServices" */ './container/PackagesServices/Tablines/AgentDetailsViewPage'
        ),
      loading: Loading,
      modules: ['PackageServices'],
    }),
    exact: true,
  },
  {
    path: CUST_HOME,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "PackageServices" */ './container/CustomHome/Home'
        ),
      loading: Loading,
      modules: ['PackageServices'],
    }),
    exact: true,
  },
  {
    path: HOME_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "Home" */ './container/PackagesServices/Tablines/AgentDetailsViewPage'
        ),
      loading: Loading,
      modules: ['Home'],
    }),
    exact: true,
  },
  {
    path: LOGIN_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "SignIn" */ './container/SignIn/SignIn'),
      loading: Loading,
      modules: ['SignIn'],
    }),
  },
  {
    path: BUSINESS_DETAILS_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "BusinessDetails" */ './container/BusinessDetails/index'
        ),
      loading: Loading,
      modules: ['BusinessDetails'],
    }),
  },
  {
    path: REGISTRATION_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "SignUp" */ './container/SignUp/SignUp'),
      loading: Loading,
      modules: ['SignUp'],
    }),
  },
  {
    path: FORGET_PASSWORD_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "ForgetPassWord" */ './container/ForgetPassWord/ForgetPassWord'
        ),
      loading: Loading,
      modules: ['ForgetPassWord'],
    }),
  },
  {
    path: `${SINGLE_POST_PAGE}/:slug`,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "SinglePageView" */ './container/SinglePage/SinglePageView'
        ),
      loading: Loading,
      modules: ['SinglePageView'],
    }),
  },
  {
    path: LISTING_POSTS_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Listing" */ './container/Listing/Listing'),
      loading: Loading,
      modules: ['Listing'],
    }),
  },
  {
    path: AGENT_PROFILE_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "AgentDetailsViewPage" */ './container/Agent/AccountDetails/AgentDetailsViewPage'
        ),
      loading: Loading,
      modules: ['AgentDetailsViewPage'],
    }),
  },
  {
    path: PRIVACY_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "privacy" */ './container/Privacy/Privacy'),
      loading: Loading,
      modules: ['Privacy'],
    }),
  },
  {
    path: CAREER_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "privacy" */ './container/Careers/Careers'),
      loading: Loading,
      modules: ['Career'],
    }),
  },
  {
    path: AGENT_PROFILE_CONTACT,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "privacy" */ './container/ContactUs/ContactUs'
        ),
      loading: Loading,
      modules: ['Contact Us'],
    }),
  },
  {
    path: PRICING_PLAN_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Pricing" */ './container/Pricing/Pricing'),
      loading: Loading,
      modules: ['Pricing'],
    }),
  },
];

/**
 *
 * Protected Route Component
 *
 */

const AddHotel = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RenderListingForm" */ './container/Listing/RenderListingForm'
    ),
  loading: Loading,
  modules: ['RenderListingForm'],
});

const AgentAccountSettingsPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AgentAccountSettingsPage" */ './container/Agent/AccountSettings/AgentAccountSettingsPage'
    ),
  loading: Loading,
  modules: ['AgentAccountSettingsPage'],
});

/**
 *
 * Not Found Route Component
 *
 */

const NotFound = Loadable({
  loader: () =>
    import(/* webpackChunkName: "NotFound" */ './container/404/404'),
  loading: Loading,
  modules: ['NotFound'],
});

// const ByTag = Loadable({
//   loader: () => import(/* webpackChunkName: "All" */ './container/AllPackages'),
//   loading: Loading,
//   modules: ['All'],
// });

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { loggedIn } = useContext(AuthContext);
  return (
    <Route
      render={props =>
        loggedIn ? <Component {...props} /> : <Redirect to={LOGIN_PAGE} />
      }
      {...rest}
    />
  );
};

/**
 *
 * Overall Router Component
 *
 */

const Routes = () => {
  return (
    <Layout>
      <Switch>
        {routes.map(({ path, component, exact = false }) => {
          return (
            <Route key={path} path={path} exact={exact} component={component} />
          );
        })}
        <ProtectedRoute path={ADD_HOTEL_PAGE} component={AddHotel} />
        <ProtectedRoute
          path={AGENT_ACCOUNT_SETTINGS_PAGE}
          component={AgentAccountSettingsPage}
        />
        {/* <Route path={'/:tag'} component={ByTag} /> */}
        <Route component={NotFound} />
      </Switch>
    </Layout>
  );
};

export default Routes;
