import { Component } from 'react';
import Geocode from 'react-geocode';
Geocode.setApiKey('AIzaSyBUH2yhTSBPhrU3UdDX_POt3W4X04N4lSU');

class GeoLocation extends Component {
  _displayLocationInfo = position => {};
  _getLocation() {
    navigator.geolocation.getCurrentPosition(this._displayLocationInfo);
    return null;
  }
  render() {
    return this._getLocation();
  }
}

export const _displayLocationInfo = (position, callback) => {
  Geocode.fromLatLng(position.lat, position.lng).then(
    response => {
      const address = response.results[0].formatted_address;
      callback(address);
    },
    error => {
      console.error(error);
    }
  );
};

export const _getLocation = () => {
  navigator.geolocation.getCurrentPosition(_displayLocationInfo);
  return null;
};

export const getAddress = async callback => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      async props => {
        await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${props.coords.latitude},${props.coords.longitude}&key=AIzaSyBUH2yhTSBPhrU3UdDX_POt3W4X04N4lSU`
        )
          .then(res => res.json())
          .then(resJ => {
            callback(resJ.results[0].formatted_address);
          });
      },
      failure => {
        callback({
          Heading: 'Only secure origins are allowed',
          type: 'error',
        });
      }
    );
  } else {
    callback({
      Heading: 'Sorry, your browser does not support geolocation services.',
      type: 'error',
    });
  }
};

export const GetCurrentArea = async (latitude, longitude, callback) => {
  await Geocode.fromLatLng(latitude, longitude).then(
    async response => {
      await callback(response.results[0].formatted_address);
    },
    error => {
      console.error(error);
    }
  );
};
export const GetLocationByAddress = async (address, callback) => {
  await Geocode.fromAddress(address).then(
    async response => {
      const { lat, lng } = response.results[0].geometry.location;
      console.log(lat, lng);
      await callback(lat, lng);
    },
    error => {
      console.error(error);
    }
  );
};

export const GetAddressByIP = async callback => {
  //https://freegeoip.app/json/
  //https://geoip-db.com/jsonp
  //fetch(`https://freegeoip.app/json/`)

  fetch(`https://ipapi.co/json/`)
    .then(res => res.json())
    .then(resJ => {
      callback(resJ);
    });
};

export default GeoLocation;
